import Release1 from './Release1.jpg'
import Release2 from './Release2.jpg'
import Release3 from './Release3.png'
import Release4 from './Release4.JPG'
import Release5 from './Release5.jpg'
import Release6 from './Release6.jpg'
import Release7 from './Release7.jpg'
import Release8 from './Release8.jpg'

let release = [
    { src: Release1, name: '' },
    { src: Release2, name: '' },
    { src: Release3, name: '' }, 
    { src: Release4, name: '' }, 
    { src: Release5, name: '' }, 
    { src: Release6, name: '' }, 
    { src: Release7, name: '' }, 
    { src: Release8, name: '' }, 
];

export default release;