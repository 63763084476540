import BV1 from './BV1.jpg'
import BV2 from './BV2.jpg'
import BV3 from './BV3.jpg'
import BV4 from './BV4.jpg'

let bvc = [
    { src: BV1, name: '' },
    { src: BV2, name: '' },
    { src: BV3, name: '' }, 
    { src: BV4, name: '' }, 
];

export default bvc;