import tat1 from './tat1.jpg'
import tat2 from './tat2.jpg'
import tat3 from './tat3.jpg'

let tat = [
    { src: tat1, name: '' },
    { src: tat2, name: '' },
    { src: tat3, name: '' }, 
];

export default tat;